import { getSessionItem, KEYS } from '../store/storage';
import moment from 'moment';
import {
  getRestaurantProfile,
  getTableSetting,
  getOpenTimeRegular,
  getOpenTimeSpecial,
  getCloseTime,
  updatePreference,
  createCloseTime,
  updateCloseTime,
  deleteCloseTime,
  createTableSetting,
  updateTableSetting,
  deleteTableSetting,
  createOpenTimeRegular,
  updateOpenTimeRegular,
  deleteOpenTimeRegular,
  createOpenTimeSpecial,
  updateOpenTimeSpecial,
  deleteOpenTimeSpecial,
} from '../services/reservation';
import { parseMinutes, WEEK_NAMES } from 'src/utils/time';

const BASE = 'RESERVATION';
export const actions = {
  loadingPreference: `${BASE}_LOADING_PREFERENCE`,
  loadPreference: `${BASE}_LOAD_PREFERENCE`,
  updatePreference: `${BASE}_UPDATE_PREFERENCE`,
  updateCloseTime: `${BASE}_UPDATE_CLOSE_TIME`,
  updateTableSetting: `${BASE}_UPDATE_TABLE_SETTING`,
  updateOpenTimeRegularSetting: `${BASE}_UPDATE_OPEN_TIME_REGULAR`,
  updateOpenTimeSpecialSetting: `${BASE}_UPDATE_OPEN_TIME_SPECIAL`,
};

export const loadReservationPreference = (callback) => async (dispatch, getState) => {
  dispatch({
    type: actions.loadingPreference,
  });

  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let responseList;
  try {
    responseList = await Promise.all([
      getRestaurantProfile({ locale, restaurant_id }),
      getTableSetting({ locale, restaurant_id }),
      getOpenTimeRegular({ locale, restaurant_id }),
      getOpenTimeSpecial({ locale, restaurant_id }),
      getCloseTime({ locale, restaurant_id }),
    ]);
  } catch (e) {
    responseList = [{ success: false }, { success: false }, { success: false }, { success: false }, { success: false }];
  }

  let failedList = responseList.filter(({ success }) => {
    return !success;
  });

  if (failedList.length !== 0) {
    callback &&
      callback({
        success: false,
      });
    return;
  }
  let restaurantProfile = responseList[0].data;
  let tableSettingList = responseList[1].data || [];
  let openTimeRegularList = responseList[2].data || [];
  let openTimeSpecList = responseList[3].data || [];
  let closeTimeList = responseList[4].data || [];

  let {
    advance_notice_in_minutes, //提醒时间
    advance_reserve_in_days, //可提前预定天数
    advance_reserve_in_minutes, //最短可提前预约时间
    enable_google_reservation, //Google在线预约
    max_party_size, //可预订人数
    min_party_size, //可预订人数
    segment_mins, //预订间隔
    sms_cancel_foreign_content,
    sms_cancel_content,
    note, //预约须知
    foreign_note, //
    auto_send_remind_sms, //是否支持自动发送短信
    show_estimated_minutes,
  } = restaurantProfile;
  let {
    day: notify_time_day,
    hour: notify_time_hour,
    min: notify_time_min,
  } = parseMinutes(advance_notice_in_minutes || 0);
  let {
    day: advance_reserve_day,
    hour: advance_reserve_hour,
    min: advance_reserve_min,
  } = parseMinutes(advance_reserve_in_minutes || 0);
  let _tableSettingList_ = (tableSettingList || []).map((item) => {
    return {
      id: item.id,
      name: item.name,
      foreignName: item.foreign_name,
      capacityMin: item.min_capacity,
      capacityMax: item.max_capacity,
      duration: item.duration,
      sort_sequence: 0,
    };
  });
  let responseParsedData = {
    restaurantProfile: {
      notify_time: [
        {
          name: 'days',
          foreignName: '天',
          value: notify_time_day,
        },
        {
          name: 'Hr',
          foreignName: '小时',
          value: notify_time_hour,
        },
        {
          name: 'min',
          foreignName: '分钟',
          value: notify_time_min,
        },
      ],
      advance_reserve_time: [
        {
          name: 'days',
          foreignName: '天',
          value: advance_reserve_day,
        },
        {
          name: 'Hr',
          foreignName: '小时',
          value: advance_reserve_hour,
        },
        {
          name: 'min',
          foreignName: '分钟',
          value: advance_reserve_min,
        },
      ],
      advance_reserve_in_days,
      enable_google_reservation,
      partysize_min: min_party_size,
      partysize_max: max_party_size,
      segment_interval: segment_mins,
      sms_cancel_content,
      sms_cancel_foreign_content,
      note,
      foreign_note,
      auto_send_remind_sms,
      show_estimated_minutes,
    },
    tableSettingList: _tableSettingList_,
    openTimeRegularList: openTimeRegularList.map((item) => {
      let { id, tables, weekdays, start_time, end_time } = item;
      return {
        id,
        weeks: weekdays.map((weekName) => {
          return WEEK_NAMES.indexOf(weekName);
        }),
        time: [
          moment(`${moment().format('YYYY/MM/DD')} ${start_time}`, 'YYYY/MM/DD HH:mm'),
          moment(`${moment().format('YYYY/MM/DD')} ${end_time}`, 'YYYY/MM/DD HH:mm'),
        ],
        tables: tables
          .map((bindedTable) => {
            let matchedTable = _tableSettingList_.find(({ id }) => bindedTable.category_id + '' === id + '');
            if (matchedTable) {
              return {
                ...matchedTable,
                count: bindedTable.count,
              };
            }
            return null;
          })
          .filter((item) => !!item),
      };
    }),

    //date: [moment().add(1, 'day'), moment().add(6, 'day')],
    //     time: [
    //       moment(`${moment().format('YYYY/MM/DD')} ${'11:00'}`, 'YYYY/MM/DD HH:mm'),
    //       moment(`${moment().format('YYYY/MM/DD')} ${'17:00'}`, 'YYYY/MM/DD HH:mm'),
    //     ],

    openTimeSpecList: openTimeSpecList.map((item) => {
      let { id, tables, start_date, end_date, start_time, end_time } = item;
      return {
        id,
        date: [moment(`${start_date} 00:00`, 'YYYY/MM/DD HH:mm'), moment(`${end_date} 23:59`, 'YYYY/MM/DD  HH:mm')],
        time: [
          moment(`${moment().format('YYYY/MM/DD')} ${start_time}`, 'YYYY/MM/DD HH:mm'),
          moment(`${moment().format('YYYY/MM/DD')} ${end_time}`, 'YYYY/MM/DD HH:mm'),
        ],
        tables: tables
          .map((bindedTable) => {
            let matchedTable = _tableSettingList_.find(({ id }) => bindedTable.category_id + '' === id + '');
            if (matchedTable) {
              return {
                ...matchedTable,
                count: bindedTable.count,
              };
            }
            return null;
          })
          .filter((item) => !!item),
      };
    }),
    closeTimeList: (closeTimeList || []).map((item) => {
      return {
        id: item.id,
        date: [moment(`${item.from}`, 'YYYY/MM/DD HH:mm'), moment(`${item.to}`, 'YYYY/MM/DD HH:mm')],
      };
    }),
  };
  callback &&
    callback({
      success: true,
      data: {
        ...responseParsedData,
      },
    });
  dispatch({
    type: actions.loadPreference,
    payload: responseParsedData,
  });
};

export const updateReservationPreference = (newePreference, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await updatePreference({
      locale,
      restaurant_id,
      preference: {
        ...newePreference,
        advance_reserve_in_minutes: newePreference.advance_reserve_time.reduce((minutes, { name, value }, index) => {
          if (index === 0) {
            minutes = minutes + (value || 0) * 24 * 60;
          } else if (index === 1) {
            minutes = minutes + (value || 0) * 60;
          } else {
            minutes = minutes + (value || 0);
          }
          return minutes;
        }, 0),

        advance_notice_in_minutes: newePreference.notify_time.reduce((minutes, { name, value }, index) => {
          if (index === 0) {
            minutes = minutes + (value || 0) * 24 * 60;
          } else if (index === 1) {
            minutes = minutes + (value || 0) * 60;
          } else {
            minutes = minutes + (value || 0);
          }
          return minutes;
        }, 0),
      },
    });
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  dispatch({
    type: actions.updatePreference,
    payload: newePreference,
  });
};

export const createOrUpdateCloseTime = (closeTime, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const { closeTimeList } = getState().reservation;
  let response;
  try {
    if (!closeTime.id) {
      response = await createCloseTime({
        locale,
        restaurant_id,
        closeTime: { ...closeTime },
      });
    } else {
      response = await updateCloseTime({
        locale,
        restaurant_id,
        closeTime: { ...closeTime },
      });
    }
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  let responseCloseTime = {
    id: response.data.id,
    date: [moment(`${response.data.from}`, 'YYYY/MM/DD HH:mm'), moment(`${response.data.to}`, 'YYYY/MM/DD HH:mm')],
  };

  let newcloseTimeList = [...(closeTimeList || [])];

  let existedIndex = newcloseTimeList.findIndex(({ id }) => id === closeTime.id);
  if (existedIndex !== -1) {
    newcloseTimeList.splice(existedIndex, 1, responseCloseTime);
  } else {
    newcloseTimeList.push(responseCloseTime);
  }

  dispatch({
    type: actions.updateCloseTime,
    payload: newcloseTimeList,
  });
};

export const deleteSpecCloseTime = (closeTime, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const { closeTimeList } = getState().reservation;
  let response;
  try {
    response = await deleteCloseTime({
      locale,
      restaurant_id,
      closeTime: closeTime,
    });
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  let existedIndex = closeTimeList.findIndex(({ id }) => id === closeTime.id);
  let newcloseTimeList = [...(closeTimeList || [])];
  newcloseTimeList.splice(existedIndex, 1);
  dispatch({
    type: actions.updateCloseTime,
    payload: newcloseTimeList,
  });
};

export const createOrUpdateTableSetting = (tableSetting, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const { tableSettingList } = getState().reservation;
  let response;
  try {
    if (!tableSetting.id) {
      response = await createTableSetting({
        locale,
        restaurant_id,
        newTableSetting: { ...tableSetting },
      });
    } else {
      response = await updateTableSetting({
        locale,
        restaurant_id,
        tableSetting: { ...tableSetting },
      });
    }
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  let newTableList = [...(tableSettingList || [])];
  let responseTableSetting = {
    id: response.data.id,
    name: response.data.name,
    foreignName: response.data.foreign_name,
    capacityMin: response.data.min_capacity,
    capacityMax: response.data.max_capacity,
    duration: response.data.duration,
    sort_sequence: response.data.sequence,
  };
  if (!tableSetting.id) {
    newTableList.push(responseTableSetting);
  } else {
    let matchedIndex = newTableList.findIndex(({ id }) => id === tableSetting.id);
    if (matchedIndex !== -1) {
      newTableList.splice(matchedIndex, 1, responseTableSetting);
    }
  }
  dispatch({
    type: actions.updateTableSetting,
    payload: newTableList,
  });
};

export const deleteSpecTableSetting = (tableSetting, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const { tableSettingList } = getState().reservation;
  let response;
  try {
    response = await deleteTableSetting({
      locale,
      restaurant_id,
      tableSetting: { ...tableSetting },
    });
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  let newTableList = [...(tableSettingList || [])];

  let matchedIndex = newTableList.findIndex(({ id }) => id === tableSetting.id);
  if (matchedIndex !== -1) {
    newTableList.splice(matchedIndex, 1);
  }

  dispatch({
    type: actions.updateTableSetting,
    payload: newTableList,
  });
};

export const createOrUpdateOpenTimeRegular = (openTimeRegular, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const { openTimeRegularList, tableSettingList } = getState().reservation;
  let response;
  try {
    if (!openTimeRegular.id) {
      response = await createOpenTimeRegular({
        locale,
        restaurant_id,
        openTimeRegular: { ...openTimeRegular },
      });
    } else {
      response = await updateOpenTimeRegular({
        locale,
        restaurant_id,
        openTimeRegular: { ...openTimeRegular },
      });
    }
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  let newOpenTimeRegularList = [...(openTimeRegularList || [])];

  let responseOpenTime = {
    id: response.data.id,
    weeks: response.data.weekdays.map((weekName) => {
      return WEEK_NAMES.indexOf(weekName);
    }),
    time: [
      moment(`${moment().format('YYYY/MM/DD')} ${response.data.start_time}`, 'YYYY/MM/DD HH:mm'),
      moment(`${moment().format('YYYY/MM/DD')} ${response.data.end_time}`, 'YYYY/MM/DD HH:mm'),
    ],
    tables: response.data.tables
      .map((bindedTable) => {
        let matchedTable = tableSettingList.find(({ id }) => bindedTable.category_id + '' === id + '');
        if (matchedTable) {
          return {
            ...matchedTable,
            count: bindedTable.count,
          };
        }
        return null;
      })
      .filter((item) => !!item),
  };
  if (!openTimeRegular.id) {
    newOpenTimeRegularList.push(responseOpenTime);
  } else {
    let matchedIndex = newOpenTimeRegularList.findIndex(({ id }) => id === openTimeRegular.id);
    if (matchedIndex !== -1) {
      newOpenTimeRegularList.splice(matchedIndex, 1, responseOpenTime);
    }
  }
  dispatch({
    type: actions.updateOpenTimeRegularSetting,
    payload: newOpenTimeRegularList,
  });
};

export const deleteSpecOpenTimeRegular = (openTimeRegular, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const { openTimeRegularList, tableSettingList } = getState().reservation;
  let response;
  try {
    response = await deleteOpenTimeRegular({
      locale,
      restaurant_id,
      openTimeRegular: { ...openTimeRegular },
    });
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  let newOpenTimeRegularList = [...(openTimeRegularList || [])];

  let matchedIndex = newOpenTimeRegularList.findIndex(({ id }) => id === openTimeRegular.id);
  if (matchedIndex !== -1) {
    newOpenTimeRegularList.splice(matchedIndex, 1);
  }

  dispatch({
    type: actions.updateOpenTimeRegularSetting,
    payload: newOpenTimeRegularList,
  });
};

export const createOrUpdateOpenTimeSpecial = (openTimeSpecial, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const { openTimeSpecList, tableSettingList } = getState().reservation;
  let response;
  try {
    if (!openTimeSpecial.id) {
      response = await createOpenTimeSpecial({
        locale,
        restaurant_id,
        openTimeSpecial: { ...openTimeSpecial },
      });
    } else {
      response = await updateOpenTimeSpecial({
        locale,
        restaurant_id,
        openTimeSpecial: { ...openTimeSpecial },
      });
    }
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  let newOpenTimeSpecialList = [...(openTimeSpecList || [])];
  let responseOpenTime = {
    id: response.data.id,
    date: [
      moment(`${response.data.start_date} 00:00`, 'YYYY/MM/DD HH:mm'),
      moment(`${response.data.end_date} 23:59`, 'YYYY/MM/DD  HH:mm'),
    ],
    time: [
      moment(`${moment().format('YYYY/MM/DD')} ${response.data.start_time}`, 'YYYY/MM/DD HH:mm'),
      moment(`${moment().format('YYYY/MM/DD')} ${response.data.end_time}`, 'YYYY/MM/DD HH:mm'),
    ],
    tables: response.data.tables
      .map((bindedTable) => {
        let matchedTable = tableSettingList.find(({ id }) => bindedTable.category_id + '' === id + '');
        if (matchedTable) {
          return {
            ...matchedTable,
            count: bindedTable.count,
          };
        }
        return null;
      })
      .filter((item) => !!item),
  };
  if (!openTimeSpecial.id) {
    newOpenTimeSpecialList.push(responseOpenTime);
  } else {
    let matchedIndex = newOpenTimeSpecialList.findIndex(({ id }) => id === openTimeSpecial.id);
    if (matchedIndex !== -1) {
      newOpenTimeSpecialList.splice(matchedIndex, 1, responseOpenTime);
    }
  }
  dispatch({
    type: actions.updateOpenTimeSpecialSetting,
    payload: newOpenTimeSpecialList,
  });
};

export const deleteSpecOpenTimeSpecial = (openTimeSpecial, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const { openTimeSpecList, tableSettingList } = getState().reservation;
  let response;
  try {
    response = await deleteOpenTimeSpecial({
      locale,
      restaurant_id,
      openTimeSpecial: { ...openTimeSpecial },
    });
  } catch (e) {
    response = { success: false };
  }

  callback(response.success);

  if (!response.success) return;

  let newOpenTimeSpecListList = [...(openTimeSpecList || [])];

  let matchedIndex = newOpenTimeSpecListList.findIndex(({ id }) => id === openTimeSpecial.id);
  if (matchedIndex !== -1) {
    newOpenTimeSpecListList.splice(matchedIndex, 1);
  }

  dispatch({
    type: actions.updateOpenTimeSpecialSetting,
    payload: newOpenTimeSpecListList,
  });
};
