import { GET, POST, PUT, DELETE } from '../utils/request';
import { CLIENT_ID, LOYALTY_API_PREFIX } from '../consts';
import { WEEK_NAMES } from 'src/utils/time';
//get reservation base setting
export async function getRestaurantProfile({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/restaurant_profiles`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

//table setting
export async function getTableSetting({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/table_categories`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

//open time regular
export async function getOpenTimeRegular({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/table_schedules`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

//open time special
export async function getOpenTimeSpecial({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/prioritize_table_schedules`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

//close time
export async function getCloseTime({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/restaurant_close_times`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updatePreference({ locale, restaurant_id, preference }) {
  let {
    enable_google_reservation,
    partysize_min,
    partysize_max,
    segment_interval,
    advance_reserve_in_minutes,
    advance_reserve_in_days,
    advance_notice_in_minutes,
    sms_cancel_content,
    sms_cancel_foreign_content,
    foreign_note,
    note,
    auto_send_remind_sms,
    show_estimated_minutes,
  } = preference;
  return POST(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/restaurant_profiles`,
    {
      restaurant_id: restaurant_id,
      enable_google_reservation,
      auto_send_remind_sms,
      max_party_size: partysize_max,
      min_party_size: partysize_min,
      segment_mins: segment_interval,
      advance_reserve_in_minutes,
      advance_reserve_in_days,
      advance_notice_in_minutes,
      sms_cancel_content,
      sms_cancel_foreign_content,
      note,
      foreign_note,
      show_estimated_minutes,
    },
    {
      silence: false,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function createCloseTime({ locale, restaurant_id, closeTime }) {
  let { start, end } = closeTime;
  return POST(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/restaurant_close_times`,
    {
      restaurant_id: restaurant_id,
      restaurant_close_time: {
        from: start,
        to: end,
      },
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}
export async function updateCloseTime({ locale, restaurant_id, closeTime }) {
  let { start, end } = closeTime;
  return PUT(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/restaurant_close_times/${closeTime.id}`,
    {
      restaurant_id: restaurant_id,
      restaurant_close_time: {
        from: start,
        to: end,
      },
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function deleteCloseTime({ locale, restaurant_id, closeTime }) {
  let { id } = closeTime;
  return DELETE(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/restaurant_close_times/${id}`,
    {
      restaurant_id: restaurant_id,
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function createTableSetting({ locale, restaurant_id, newTableSetting }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/table_categories`,
    {
      restaurant_id: restaurant_id,
      table_category: {
        name: newTableSetting.name,
        foreign_name: newTableSetting.foreignName,
        duration: newTableSetting.duration,
        max_capacity: newTableSetting.capacityMax,
        min_capacity: newTableSetting.capacityMin,
        sequence: newTableSetting.sort_sequence || 0,
      },
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateTableSetting({ locale, restaurant_id, tableSetting }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/table_categories/${tableSetting.id}`,
    {
      restaurant_id: restaurant_id,
      table_category: {
        name: tableSetting.name,
        foreign_name: tableSetting.foreignName,
        duration: tableSetting.duration,
        max_capacity: tableSetting.capacityMax,
        min_capacity: tableSetting.capacityMin,
        sequence: tableSetting.sort_sequence || 0,
      },
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function deleteTableSetting({ locale, restaurant_id, tableSetting }) {
  return DELETE(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/table_categories/${tableSetting.id}`,
    {
      restaurant_id: restaurant_id,
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function createOpenTimeRegular({ locale, restaurant_id, openTimeRegular }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/table_schedules`,
    {
      restaurant_id: restaurant_id,
      table_schedule: {
        start_time: openTimeRegular.time[0].format('HH:mm'),
        end_time: openTimeRegular.time[1].format('HH:mm'),
        weekdays: openTimeRegular.weeks.map((weekIndex) => {
          return WEEK_NAMES[weekIndex];
        }),
        tables: openTimeRegular.tables
          .map(({ count, id }) => {
            if (!count) {
              return null;
            }
            return {
              category_id: id,
              count: count,
            };
          })
          .filter((item) => !!item),
      },
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateOpenTimeRegular({ locale, restaurant_id, openTimeRegular }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/table_schedules/${openTimeRegular.id}`,
    {
      restaurant_id: restaurant_id,
      table_schedule: {
        start_time: openTimeRegular.time[0].format('HH:mm'),
        end_time: openTimeRegular.time[1].format('HH:mm'),
        weekdays: openTimeRegular.weeks.map((weekIndex) => {
          return WEEK_NAMES[weekIndex];
        }),
        tables: openTimeRegular.tables
          .map(({ count, id }) => {
            if (!count) {
              return null;
            }
            return {
              category_id: id,
              count: count,
            };
          })
          .filter((item) => !!item),
      },
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function deleteOpenTimeRegular({ locale, restaurant_id, openTimeRegular }) {
  return DELETE(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/table_schedules/${openTimeRegular.id}`,
    {
      restaurant_id: restaurant_id,
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function createOpenTimeSpecial({ locale, restaurant_id, openTimeSpecial }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/prioritize_table_schedules`,
    {
      restaurant_id: restaurant_id,
      table_schedule: {
        start_date: openTimeSpecial.date[0].format('YYYY-MM-DD'),
        end_date: openTimeSpecial.date[1].format('YYYY-MM-DD'),
        start_time: openTimeSpecial.time[0].format('HH:mm'),
        end_time: openTimeSpecial.time[1].format('HH:mm'),
        tables: openTimeSpecial.tables
          .map(({ count, id }) => {
            if (!count) {
              return null;
            }
            return {
              category_id: id,
              count: count,
            };
          })
          .filter((item) => !!item),
      },
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateOpenTimeSpecial({ locale, restaurant_id, openTimeSpecial }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/prioritize_table_schedules/${openTimeSpecial.id}`,
    {
      restaurant_id: restaurant_id,
      table_schedule: {
        start_date: openTimeSpecial.date[0].format('YYYY-MM-DD'),
        end_date: openTimeSpecial.date[1].format('YYYY-MM-DD'),
        start_time: openTimeSpecial.time[0].format('HH:mm'),
        end_time: openTimeSpecial.time[1].format('HH:mm'),
        tables: openTimeSpecial.tables
          .map(({ count, id }) => {
            if (!count) {
              return null;
            }
            return {
              category_id: id,
              count: count,
            };
          })
          .filter((item) => !!item),
      },
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function deleteOpenTimeSpecial({ locale, restaurant_id, openTimeSpecial }) {
  return DELETE(
    `${LOYALTY_API_PREFIX}/api/reservation/v1/prioritize_table_schedules/${openTimeSpecial.id}`,
    {
      restaurant_id: restaurant_id,
    },
    {
      silence: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}
